import Loader from "@/components/Loader";
import globalModel from "@/models/global.model";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import KeepAlive from "keepalive-for-react";
import { Suspense, useMemo } from "react";
import {
  Await,
  Navigate,
  useLocation,
  useMatch,
  useNavigate,
  useOutlet,
} from "react-router-dom";
import { Image, Tabbar, TabbarItem, Toast } from "react-vant";
import styles from "./index.module.css";

const tabs = [
  {
    name: "/",
    title: "首页",
    active: require("@/assets/icons/home-active.png").default,
    inactive: require("@/assets/icons/home.png").default,
  },
  {
    name: "/market",
    title: "市场",
    active: require("@/assets/icons/market-active.png").default,
    inactive: require("@/assets/icons/market.png").default,
  },
  {
    name: "/activity",
    title: "活动",
    active: require("@/assets/icons/activity-active.png").default,
    inactive: require("@/assets/icons/activity.png").default,
  },
  {
    name: "/notice",
    title: "公告",
    active: require("@/assets/icons/notice-active.png").default,
    inactive: require("@/assets/icons/notice.png").default,
  },
  {
    name: "/mine",
    title: "我的",
    active: require("@/assets/icons/mine-active.png").default,
    inactive: require("@/assets/icons/mine.png").default,
  },
];

export default () => {
  const nav = useNavigate();
  const outlet = useOutlet();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    nav(value);
  };

  const isTabView = useMemo(() => {
    if (pathname.startsWith("/activity") && !pathname.includes("detail")) {
      return true;
    }
    return (
      tabs.findIndex((item) => {
        return pathname === item.name;
      }) >= 0
    );
  }, [pathname]);

  const cacheKey = useMemo(() => {
    return location.pathname + location.search;
  }, [location]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Await resolve={globalModel.load()}>
          {(res) => {
            if (!res?.data?.user) {
              const expire = localStorage.getItem("expire");
              if (dayjs().isAfter(dayjs(expire))) {
                Toast("凭证已过期, 请重新登录");
                localStorage.setItem("token", "");
              }
              const token = localStorage.getItem("token");
              if (!token) {
                return (
                  <Navigate to="/login" state={{ from: location }} replace />
                );
              }
            }
            return (
              <KeepAlive
                activeName={cacheKey}
                cache={!pathname.includes("/detail")}
              >
                {/* <motion.div
                  className={styles.container}
                  initial={{
                    scaleX: 0,
                    opacity: 0,
                  }}
                  animate={{
                    scaleX: 1,
                    opacity: 1,
                    transition: { duration: 0.3, ease: "circOut" },
                  }}
                  exit={{
                    scaleX: 0,
                    opacity: 0,
                    transition: { duration: 0.3, ease: "circIn" },
                  }}
                > */}
                {outlet}
                {/* </motion.div> */}
              </KeepAlive>
            );
          }}
        </Await>
      </Suspense>
      {isTabView && (
        <Tabbar
          className={styles.tabbar}
          safeAreaInsetBottom
          fixed
          placeholder
          border={false}
          value={pathname}
          onChange={(value) => setRouteActive(value.toString())}
        >
          {tabs.map(({ title, active, inactive, ...tab }) => (
            <TabbarItem key={tab.name} {...tab}>
              <div
                className={`${styles.item} ${
                  pathname === tab.name ? styles.active : null
                }`}
              >
                <div
                  className={`${styles.icon} ${
                    pathname === tab.name ? styles.active : null
                  }`}
                >
                  <Image src={pathname === tab.name ? active : inactive} />
                </div>
                <div className={styles.text}>{title}</div>
              </div>
            </TabbarItem>
          ))}
        </Tabbar>
      )}
    </>
  );
};
